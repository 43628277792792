// index.tsx
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "primeicons/primeicons.css";
import { PrimeReactProvider } from "primereact/api";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { DataContextProvider } from "./context/DataContext ";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(

    <PrimeReactProvider>
       <DataContextProvider>
       <App />
       </DataContextProvider>
    </PrimeReactProvider>

);

reportWebVitals();