import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";

interface MediaPopUpProps {
  addNewActivityPopup: boolean;
  setMediaPopUp: (visible: boolean) => void;
  inputType: "audio" | "video";
  mediaUrl: string;
}

const MediaPopUp: React.FC<MediaPopUpProps> = ({
  addNewActivityPopup,
  setMediaPopUp,
  inputType,
  mediaUrl,
}) => {
  const mediaRef = useRef<HTMLAudioElement | HTMLVideoElement | null>(null);
  const [currentMediaUrl, setCurrentMediaUrl] = useState<string | null>(null);
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);

  const onCancelClick = () => {
    if (mediaRef.current) {
      mediaRef.current.pause(); // Stop playback
      mediaRef.current.currentTime = 0; // Reset playback position
    }
    setIsPopupVisible(false);
    setCurrentMediaUrl(null); // Clear media URL
    setTimeout(() => {
      setMediaPopUp(false); // Notify parent after a delay to avoid rapid reopens
    }, 100);
  };

  useEffect(() => {
    if (addNewActivityPopup) {
      // Open popup and load media
      setIsPopupVisible(true);

      // Reset the media element before loading the new URL
      setCurrentMediaUrl(null);

      // Delay to allow the previous media to be cleared
      const timer = setTimeout(() => {
        setCurrentMediaUrl(mediaUrl); // Set the new media URL
      }, 100); // Small delay to avoid the glitch

      return () => clearTimeout(timer);
    } else {
      setIsPopupVisible(false);
    }
  }, [addNewActivityPopup, mediaUrl]);

  const header = (
    <div className="p-d-flex p-jc-between p-ai-center">
      <span>{inputType === "audio" ? "Play Audio" : "Play Video"}</span>
      <Button
        icon="pi pi-times"
        className="p-button-rounded p-button-text popup-close-button"
        onClick={onCancelClick}
      />
    </div>
  );

  return (
    <Dialog
      className="popup popup-card-layout"
      header={header}
      draggable={false}
      visible={isPopupVisible}
      style={{ width: "40vw" }}
      onHide={onCancelClick}
    >
      <div style={{ textAlign: "center", padding: "20px" }}>
        {currentMediaUrl ? (
          inputType === "audio" ? (
            <audio
              ref={(el) => (mediaRef.current = el)}
              controls
              style={{ width: "100%" }}
            >
              <source src={currentMediaUrl} type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>
          ) : (
            <video
              ref={(el) => (mediaRef.current = el)}
              controls
              style={{ width: "100%" }}
            >
              <source src={currentMediaUrl} type="video/mp4" />
              Your browser does not support the video element.
            </video>
          )
        ) : (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <p>Loading media...</p>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default MediaPopUp;
