import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { ConfirmDialog } from "primereact/confirmdialog";
import attributeServices from "../services/attributeServices";
import AudioLayout from "../components/MediaData/AudioLayout";
import VideoLayout from "../components/MediaData/VideoLayout";

const MediaData: React.FC = () => {
  const [mediaData, setMediaData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true); 
  const [activeTab, setActiveTab] = useState<number>(0);
  const toast = React.useRef<Toast>(null);

  useEffect(() => {
    fetchDataDetails();
  }, []);

  const fetchDataDetails = async () => {
    setLoading(true);

    const poiId = sessionStorage.getItem("poiId");
    const attributeId = sessionStorage.getItem("attributeId");
    const data = {
      PoiId: poiId,
      AttributeId: attributeId,
    };

    try {
      const response = await getMediaDetails(data);
      setTimeout(() => {
        setMediaData(response);
        setLoading(false);
      }, 1000); 
    } catch (error) {
      console.error("Error fetching media details:", error);
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong",
        life: 3000,
      });
      setLoading(false);
    }
  };

  const getMediaDetails = async (data: any) => {
    return await attributeServices.GetPoiMediaDetails(data);
  };

  const audioData = mediaData.data?.find((item: any) => item.p_inputtype === "Audios");
  const videoData = mediaData.data?.find((item: any) => item.p_inputtype === "Videos");

  const enrichedVideoData = videoData
    ? JSON.parse(videoData.p_file).map((file: any) => ({
        ...file,
        p_attributedetailid: videoData.p_attributedetailid,
      }))
    : [];

  const enrichedAudioData = audioData
    ? JSON.parse(audioData.p_file).map((file: any) => ({
        ...file,
        p_attributedetailid: audioData.p_attributedetailid,
      }))
    : [];

  return (
    <div className="mediadata-container">
      <Toast ref={toast} />
      <ConfirmDialog />
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", 
          }}
        >
          <ProgressSpinner
            style={{ width: "70px", height: "70px" }}
            strokeWidth="2"
            fill="var(--surface-ground)"
            animationDuration=".9s"
          />
        </div>
      ) : (
        <div className="mediadata-card">
          <div className="mediacard-tab-section">
            <div className="tab-sectionTable">
              <TabView
                activeIndex={activeTab}
                onTabChange={(e) => setActiveTab(e.index)}
              >
                {audioData && (
                  <TabPanel header="Audio">
                    <AudioLayout
                      data={enrichedAudioData}
                      loading={loading}
                      refreshData={fetchDataDetails}
                      toast={toast}
                    />
                  </TabPanel>
                )}
                {videoData && (
                  <TabPanel header="Video">
                    <VideoLayout
                      data={enrichedVideoData}
                      loading={loading}
                      refreshData={fetchDataDetails}
                      toast={toast}
                    />
                  </TabPanel>
                )}
              </TabView>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MediaData;
