import { RouteObject, Navigate } from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import MainLayout from "../components/common/MainLayOut";
import PageNotFound from "../pages/PageNotFound";
import ForgotPassWord from "../pages/ForgetPassword";
import Dashboard from "../components/dashboard/Dashboard";
import Users from "../components/users/User";
import AreaLayout from "../components/area/AreaLayout";
import SubAreaLayout from "../components/subarea/SubAreaLayout";
import PoiLayout from "../components/poi/PoiLayout";
import CmsLayout from "../components/cms/CmsLayout";
import Protocol from "../components/protocol/Protocol";
import ProfileLayout from "../components/profile/ProfileLayout";
import Visitors from "../components/visitors/Visitors";
import ChangePassWord from "../pages/ChangePassword";
import CreatePassWord from "../pages/CreatePassword";
import ResetPassWord from "../pages/ResetPassword";
import SettingMenus from "../components/roleSetting/SettingMenus";
import ReportLayout from "../components/Report/ReportLayout";
import MediaData from "../pages/MediaData";


// Define the route paths as constants for better readability

const DASHBOARD_PATH = "/dashboard";
const EMPLOYEE_PATH = "/employee";
const AREA_PATH = "/area";
const SUBAREA_PATH = "/subarea";
const VISITORS_PATH = "/visitors";
const POI_PATH = "/POI";
const CMS_PATH = "/cms";
const PROTOCOL_PATH = "/protocol";
const SETTING_PATH = "/setting";
const LOGIN_PATH = "/";
const CREATE_PASSWORD_PATH = "/set-password/:token";
const UPDATE_PASSWORD_PATH = "/reset-password/:token";
const CHANGE_PASSWORD_PATH = "/change-password";
const FORGET_PASSWORD_PATH = "/forget-password";
const Media_Data_PATH="/media-data"
const PROFILE_PATH = "/profile";
const REPORT_PATH= "/report";

// Function to render routes based on user menu details


const renderRoutes = (): RouteObject[] => {

  const user = localStorage.getItem("user");

  let menuDetailsResponses: any[] = [];
  let roleId = null;
  if (user) {
    menuDetailsResponses = JSON.parse(user)?.menuDetailsResponses ?? [];

    roleId = JSON.parse(user).p_roleid;
  }

  if (menuDetailsResponses.length > 0) {
    return [
      {
        path: DASHBOARD_PATH,
        element: <Dashboard />,
      },
      {
        path: EMPLOYEE_PATH,
        element: <Users />,
      },
      {
        path: AREA_PATH,
        element: <AreaLayout />,
      },
      {
        path: SUBAREA_PATH,
        element: <SubAreaLayout />,
      },
      {
        path: VISITORS_PATH,
        element: <Visitors />,
      },
      {
        path: PROTOCOL_PATH,
        element: <Protocol />,
      },
      {
        path: CMS_PATH,
        element: <CmsLayout />,
      },
      {
        path: POI_PATH,
        element: <PoiLayout />,
      },
      {
        path: SETTING_PATH,
        element: roleId === 99 || roleId === 98 ? <SettingMenus /> : <Navigate to="*" replace />,
      },
      {
        path: PROFILE_PATH,
        element: <ProfileLayout />,
      },
      {
        path: CHANGE_PASSWORD_PATH,
        element: <ChangePassWord />,
      },
      {
        path: REPORT_PATH,
        element: <CmsLayout />,
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ];
  } else {
    const handleLogin = () => <Navigate to={menuDetailsResponses[0]?.p_Url} replace />;

    // If user menu details are not available, return public routes
    return [
      {
        path: LOGIN_PATH,
        element: <LoginPage handleLogin={handleLogin} />, // Removed unnecessary wrapping function
      },
      {
        path: CREATE_PASSWORD_PATH,
        element: <CreatePassWord />,
      },
      {
        path: UPDATE_PASSWORD_PATH,
        element: <ResetPassWord />,
      },
      {
        path: FORGET_PASSWORD_PATH,
        element: <ForgotPassWord />,
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
      {
        path: Media_Data_PATH,
        element: <MediaData />,
      },
    ];
  }
};

const generateRoutesWithLayout = (paths: { path: string; element: JSX.Element }[]): RouteObject[] => {
  return paths.map((route, index) => ({
    ...route,
    element: <MainLayout>{route.element}</MainLayout>,
    key: index,
  }));
};

const user = localStorage.getItem("user");
let roleId = null;
if (user !== null) {
  roleId = JSON.parse(user).p_roleid;
}


const routes: RouteObject[] = generateRoutesWithLayout([
  { path: DASHBOARD_PATH, element: <Dashboard /> },
  { path: EMPLOYEE_PATH, element: <Users /> },
  { path: AREA_PATH, element: <AreaLayout /> },
  { path: SUBAREA_PATH, element: <SubAreaLayout /> },
  { path: VISITORS_PATH, element: <Visitors /> },
  { path: PROTOCOL_PATH, element: <Protocol /> },
  { path: CMS_PATH, element: <CmsLayout /> },
  { path: POI_PATH, element: <PoiLayout /> },
  { path: REPORT_PATH, element: <ReportLayout /> },
  { path: SETTING_PATH, element: roleId === 99 || roleId === 98  ? <SettingMenus /> : <Navigate to="*" replace /> },
  { path: PROFILE_PATH, element: <ProfileLayout /> },
  { path: CHANGE_PASSWORD_PATH, element: <ChangePassWord /> },
  { path: "*", element: <PageNotFound /> },
]);

export { renderRoutes };

export default routes;
