import React, { useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import MediaPopUp from "./MediaPopUp";
import attributeServices from "../../services/attributeServices";
import poiServices from "../../services/poiServices";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";

interface VideoDetail {
  p_attributedetailid: number;
  medianame: string;
  mediaurl: string;
  poimediaid: string;
}

interface VideoDataTableProps {
  videoDetails: VideoDetail[];
  loading: boolean;
  onDeleteSuccess: () => void;
  toast: React.RefObject<Toast>;
}

const VideoDataTable: React.FC<VideoDataTableProps> = ({
  videoDetails,
  loading,
  onDeleteSuccess,
  toast
}) => {
  const [isMediaPopUpVisible, setIsMediaPopUpVisible] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<{
    inputType: "audio" | "video";
    mediaUrl: string;
  } | null>(null);

  const renderSrNo = (rowData: any, index: any) => {
    return <span>{index.rowIndex + 1}</span>;
  };

  const fetchMediaData = (rowData: VideoDetail) => {
    const mediaId = parseInt(rowData.mediaurl.split("/")[0], 10);

    if (isNaN(mediaId)) {
      console.error("Invalid mediaId extracted from mediaurl");
      return;
    }

    const data = {
      PoiId: sessionStorage.getItem("poiId"),
      AttributeId: sessionStorage.getItem("attributeId"),
      detail_id: mediaId,
      mediaUrl: rowData.mediaurl.replace(/\\$/, ""),
    };

    attributeServices.GetMediaUrlDetails(data).then((res: any) => {
      try {
        if (res.status === 200) {
          if (res.data.url && res.data.url.length > 0) {
            const mediaUrl = res.data.url;
            setSelectedMedia({ inputType: "video", mediaUrl: mediaUrl });
            setIsMediaPopUpVisible(true); // Show the pop-up after setting media data
          } else {
            console.error("No media data found");
          }
        } else {
          console.error("Error fetching media details");
        }
      } catch (error) {
        console.error("Error processing media details:", error);
      }
    });
  };

  const handlePlayClick = (rowData: VideoDetail) => {
    console.log("Row data on play:", rowData);
    fetchMediaData(rowData); // Fetch media data and open the pop-up
  };

  const onDeleteClick = (value: any) => { 
    confirmDialog({
      message: `Are you sure you want to delete this file?`,
      header: "Confirm delete field",
      icon: "pi pi-exclamation-triangle",
      accept: () => OnDeleFileItem(value),
    });
  };

  const OnDeleFileItem = (values: VideoDetail) => {
    const data = {
      poimediaid: values.poimediaid,
    };
    poiServices.DeleteFile(data)
      .then((res: any) => {

        if (res?.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: "Media deleted successfully",
            life: 3000,
          });
          setTimeout(() => {
            try {
                onDeleteSuccess();
            } catch (error) {
                console.error("Error in onDeleteSuccess:", error);
            }
        }, 700);
        } else 
          {
          toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Failed to delete file",
            life: 3000,
          });
        }
      })
      .catch((error: any) => {
        console.error("Error deleting file:", error);
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "An error occurred while deleting the file",
          life: 3000,
        });
      });
  };

  const formsBodyTemplate = (rowData: VideoDetail) => (
    <React.Fragment>
      <Button
        className="table-button custome-icon delete-icon"
        tooltip="Delete"
        onClick={() => onDeleteClick(rowData)}
      />
      <Button
        className="table-button custome-icon play-icon"
        tooltip="Play"
        onClick={() => handlePlayClick(rowData)}
      />
    </React.Fragment>
  );

  return (
    <>
    <div className="table-section">
    <Toast ref={toast} />
      <DataTable
        value={videoDetails}
        dataKey="p_attributedetailid"
        paginator
        rows={10}
        loading={loading}
        emptyMessage="No videos found."
      >
        <Column header="Sr. No." body={renderSrNo} style={{ minWidth: "3rem" }} />
        <Column field="medianame" header="Video Name" style={{ minWidth: "12rem" }} />
        <Column
          field="Action"
          header="Action"
          style={{ minWidth: "2rem" }}
          body={formsBodyTemplate}
        />
      </DataTable>

      {selectedMedia && (
        <MediaPopUp
          addNewActivityPopup={isMediaPopUpVisible}
          setMediaPopUp={setIsMediaPopUpVisible}
          inputType={selectedMedia.inputType}
          mediaUrl={selectedMedia.mediaUrl}
        />
      )}
    </div>

    </>
  );
};

export default VideoDataTable;
