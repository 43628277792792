import React, { createContext, useContext, ReactNode, useState, useMemo } from 'react';

// Define the context type
interface DataContextType {
  poiId: string;
  attributeId: string;
  setPoiId: React.Dispatch<React.SetStateAction<string>>;
  setAttributeId: React.Dispatch<React.SetStateAction<string>>;
}

const DataContext = createContext<DataContextType | undefined>(undefined);

export const useDataContext = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useDataContext must be used within a DataContextProvider');
  }
  return context;
};

interface DataContextProviderProps {
  children: ReactNode;
  initialPoiId?: string;
  initialAttributeId?: string;
}

export const DataContextProvider: React.FC<DataContextProviderProps> = ({
  children,
  initialPoiId = '',
  initialAttributeId = '',
}) => {
  const [poiId, setPoiId] = useState<string>(initialPoiId);
  const [attributeId, setAttributeId] = useState<string>(initialAttributeId);

  const value = useMemo(
    () => ({ poiId, attributeId, setPoiId, setAttributeId }),
    [poiId, attributeId]
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
