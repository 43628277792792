import React, { useEffect, useState } from "react";
import VedioDataTable from "./VideoDataTable";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";

interface VedioLayoutProps {
  data: any;
  loading: boolean;
  refreshData: () => void;
  toast: React.RefObject<Toast>;
}

const VideoLayout: React.FC<VedioLayoutProps> = ({ data, loading,refreshData,toast }) => {
  const [vedioDetails, setVedioDetails] = useState(data);

  useEffect(() => {
    setVedioDetails(data); 
  }, [data]);

  const handleDeleteSuccess = () => {
    refreshData();
  };

  return (
   <> {loading ? (
      <ProgressSpinner />
    ) : (
    <VedioDataTable
      videoDetails={vedioDetails}
      loading={loading}
      onDeleteSuccess={handleDeleteSuccess} 
      toast={toast}
      />)}
      </>
  )
};

export default VideoLayout;
