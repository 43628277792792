import React, { useEffect, useState } from "react";
import AudioDataTable from "./AudioDataTable";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";

interface AudioLayoutProps {
  data: any;
  loading: boolean;
  refreshData: () => void;
  toast: React.RefObject<Toast>;
}

const AudioLayout: React.FC<AudioLayoutProps> = ({ data, loading,refreshData,toast }) => {
  const [audioDetails, setAudioDetails] = useState(data);

  useEffect(() => {
    setAudioDetails(data); 
  }, [data]);

  const handleDeleteSuccess = () => {
    refreshData();
  };

  return (
    <>   {loading ? (
      <ProgressSpinner />
    ) : (
    <AudioDataTable
      audioDetails={audioDetails}
      loading={loading}
      onDeleteSuccess={handleDeleteSuccess} 
      toast={toast}
    />)}
    </>
  );
};

export default AudioLayout;
