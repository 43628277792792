import axios from "axios";
import authHeader from "./authHeader";

const GetAttributeDetails = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/POI/GetPOIAttributeDetails`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },

    });
    return response;
  } catch (error) {
    return error;
  }
}

const AddAttributeDetails = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/AttributeDetail/RegisterAttributeDetail`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },

    });
    return response;
  } catch (error) {
    return error;
  }
}
const GetPoiMediaDetails = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/POI/getPoiAllMediaDetails`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
const GetMediaUrlDetails = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/POI/getMediaUrlDetails`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
const attributeServices = {

  GetAttributeDetails,
  AddAttributeDetails,
  GetPoiMediaDetails,
  GetMediaUrlDetails
};

export default attributeServices;
